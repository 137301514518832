import { formatDate, NumberMxn } from "helpers";
import TextInfo from "../text-info";
import {
  FaRegCheckCircle,
  FaCloudDownloadAlt,
  FaEye,
  FaPeopleCarry,
  FaCopy,
  FaFilePdf,
} from "react-icons/fa";
import BtnNew from "components/btn-new";
import toast from "react-hot-toast";

const bgs = {
  proceso: "bg-yellow-100",
  autorizada: "bg-green-100",
  cancelada: "bg-red-100",
  solicitada: "bg-gray-100",
};

export default function ListSolicitudes({
  item,
  rol,
  loader,
  status,
  handleView,
  handleSendContratos,
  handleDownload,
  autorizar,
  CancelarSolicitud,
  setModalAcuse,
  setidGeneralSeleccionado,
  setModalAsignar,
}) {
  const { general, oferta, asesor, mesa } = item;

  const renderButtons = () => {
    if (loader) return "Procesando";

    return (
      <>
        {status !== "reasignacion" && (
          <BtnNew
            handleClick={() => {
              handleView(item);
            }}
            className="flex h-[30px] items-center justify-center rounded-[10px] bg-brand-400 p-2 text-white"
            visible={true}
            Icon={FaRegCheckCircle}
            colorIcon={"text-green-300"}
          >
            Validar Datos
          </BtnNew>
        )}

        <div className="rounded-large flex flex-col bg-blue-100 p-1">
          <span>Paquete de credito</span>
          <div className="flex flex-row justify-between">
            <BtnNew
              handleClick={() => handleDownload({ id: general.id, preview: true })}
              visible={[1, 4, 8].includes(rol)}
              Icon={FaEye}
            >
              Preview
            </BtnNew>
            <BtnNew
              handleClick={() => handleDownload({ id: general.id })}
              visible={[1, 4, 8].includes(rol)}
              Icon={FaCloudDownloadAlt}
            >
              Download
            </BtnNew>
          </div>
        </div>

        <BtnNew
          handleClick={() => {
            if (general.codeRastreo) {
              setModalAcuse(true);
              setidGeneralSeleccionado(general.id);
            } else {
              toast.error("Esta solicitud no generó la clave de rastreo", {
                position: "top-center",
              });
            }
          }}
          visible={[1, 4, 6].includes(rol)}
          Icon={FaEye}
        >
          Ver acuse cep
        </BtnNew>

        {!(general.estatus === "autorizada") && (
          <BtnNew
            handleClick={() => {
              setModalAsignar(true);
              setidGeneralSeleccionado(general.id);
            }}
            visible={[1, 4, 6].includes(rol)}
            Icon={FaPeopleCarry}
          >
            Asignar asesor
          </BtnNew>
        )}

        {autorizar({
          status: general.estatus,
          isShow: general.countSignature > 0,
          id: general.id,
          acuse: general.isAcuseCep,
        })}
        {CancelarSolicitud({
          status: general.estatus,
          id: general.id,
        })}

        {general.estatus === "proceso" && general.documentId === null && (
          <BtnNew
            handleClick={() => {
              handleSendContratos(general.id);
            }}
            visible={true}
            Icon={FaFilePdf}
          >
            Enviar contratos
          </BtnNew>
        )}
      </>
    );
  };

  return (
    <div className="grid w-full grid-cols-[auto,280px] gap-y-5 gap-x-10  bg-gray-100">
      <div className="flex flex-col gap-y-2  rounded-md bg-[#fff] px-5 py-5">
        <div className="flex flex-row gap-x-10">
          <TextInfo label="Id:" value={general.id} />
          <TextInfo
            label="ESTATUS:"
            value={
              general.documentId && general.countSignature == 0
                ? "En espera de firma"
                : general.estatus
            }
            classContainer={bgs[general.estatus]}
          />
          <TextInfo label="Fecha y Hr:" value={formatDate(general.createdAt)} />
          <TextInfo label="Zell:" value={general.zellId} />
          <TextInfo label="Devoluciones:" value={general.countDevueltos} />
          <span className="flex flex-col px-2">
            <span className="text-[12px] uppercase">Id Veridas:</span>
            <button
              onClick={() => {
                navigator.clipboard.writeText(general?.validationVeridas);
              }}
            >
              <FaCopy className="text-brand-300" />
            </button>
          </span>
          <TextInfo label="Reserva:" value={general.reserva} />
          <TextInfo
            label="CONTRATOS FIRMADOS:"
            value={general.countSignature > 0 ? "si" : "no"}
            classContainer={
              general.countSignature > 0 ? "bg-green-300" : "bg-red-300"
            }
          />
        </div>
        <div className="border-2 border-dashed"></div>

        <div className="flex flex-row gap-x-10">
          <TextInfo
            label="Nombre del cliente:"
            value={`${general.nombre} ${general.secondName} ${general.apellidoP} ${general.apellidoM}`}
          />
          <TextInfo label="Nombre del Analista:" value={mesa?.fullName} />
          <TextInfo label="Nombre del Asesor:" value={asesor?.fullName} />
        </div>
        <div className="border-2 border-dashed"></div>
        <div className="flex w-[100%] flex-row gap-4 rounded-[10px] bg-brand-100 p-2">
          <TextInfo label="Tipo:" value={oferta.tipo} />
          <TextInfo
            label="Monto del crédito:"
            value={NumberMxn(oferta?.total ?? 0)}
          />
          <TextInfo label="No de quicenas:" value={oferta?.plazo} />
          <TextInfo
            label="Pago quincenal:"
            value={NumberMxn(oferta?.pago ?? 0)}
          />
          <TextInfo
            label="Solvencia:"
            value={NumberMxn(oferta?.capacidad ?? 0)}
          />
          <TextInfo
            label="Monto a dispersar:"
            value={NumberMxn(oferta?.dispocicion ?? 0)}
          />
          <TextInfo
            label=" Pago total:"
            value={NumberMxn(oferta?.pagoTotal ?? 0)}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-2 rounded-md bg-[#fff] px-5 py-5">
        {loader ? "Procesando" : renderButtons()}
      </div>
    </div>
  );
}
