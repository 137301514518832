import React from "react";

import DashIcon from "components/icons/DashIcon";

import Solicitudes from "views/admin/solicitudes";
import Users from "views/admin/users";
import Dashboard from "views/admin/default";
import SignIn from "views/auth/SignIn";
import Montos from "views/admin/montos";
import Simulador from "views/admin/simulador";
import Banks from "views/admin/banks";
import Datas from "views/admin/datas";
import Logs from "views/admin/logs";

import { FiCreditCard } from "react-icons/fi";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";
import { FiShieldOff } from "react-icons/fi";
import { SiCashapp } from "react-icons/si";
import { FaTruckLoading } from "react-icons/fa";
import { FaUsersCog } from "react-icons/fa";
import { FaPiggyBank } from "react-icons/fa";
import { FaDatabase } from "react-icons/fa";
import { GiWoodenClogs } from "react-icons/gi";
import { FaSignLanguage } from "react-icons/fa";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    component: <Dashboard />,
    icon: <DashIcon />,
    associateBd: 1,
  },
  {
    name: "Solicitudes crédito",
    layout: "/admin",
    path: "solcitudes", // Sin barra inicial
    icon: "",
    secondary: true,
    associateBd: 2,
    icon: <FiCreditCard />,
    children: [
      {
        name: "GCDMX",
        layout: "/admin",
        path: "gcdmx", // Sin barra inicial
        icon: "",
        secondary: true,
        associateBd: 3,
        children: [
          {
            name: "Autorizadas",
            layout: "/admin",
            path: "autorizadas", // Sin barra inicial
            icon: "",
            component: <Solicitudes convenio={"GCDMX"} status={"autorizada"} />,
            secondary: true,
            associateBd: 17,
            urlFull: "/admin/solcitudes/gcdmx/autorizadas",
          },
          {
            name: "Solicitadas",
            layout: "/admin",
            path: "solicitadas", // Sin barra inicial
            icon: "",
            component: <Solicitudes convenio={"GCDMX"} status={"solicitada"} />,
            secondary: true,
            associateBd: 18,
            urlFull: "/admin/solcitudes/gcdmx/solicitadas",
          },
          {
            name: "Proceso",
            layout: "/admin",
            path: "proceso", // Sin barra inicial
            icon: "",
            component: <Solicitudes convenio={"GCDMX"} status={"proceso"} />,
            secondary: true,
            associateBd: 19,
            urlFull: "/admin/solcitudes/gcdmx/proceso",
          },
        ],
      },
    ],
  },
  {
    name: "Devoluciones",
    layout: "/admin",
    icon: "",
    path: "devoluciones", // Sin barra inicial
    component: <Solicitudes status="Devuelta" />,
    associateBd: 4,
    icon: <FiArrowLeftCircle />,
  },
  {
    name: "Reasignación",
    layout: "/admin",
    icon: "",
    path: "reasignacion", // Sin barra inicial
    component: <Solicitudes status="reasignacion" />,
    associateBd: 7,
    icon: <FaSignLanguage />,
  },
  {
    name: "Por atender",
    layout: "/admin",
    icon: "",
    path: "por-atender", // Sin barra inicial
    component: <Solicitudes status="por-atender" />,
    associateBd: 8,
    icon: <FiUsers />,
  },
  {
    name: "Canceladas",
    layout: "/admin",
    icon: "",
    path: "canceladas", // Sin barra inicial
    component: <Solicitudes status="cancelada" />,
    associateBd: 10,
    icon: <FiShieldOff />,
  },
  {
    name: "Montos",
    layout: "/admin",
    icon: "",
    path: "montos", // Sin barra inicial
    component: <Montos />,
    associateBd: 12,
    icon: <SiCashapp />,
  },
  {
    name: "Simulador",
    layout: "/admin",
    icon: "",
    path: "simulador", // Sin barra inicial
    component: <Simulador />,
    associateBd: 13,
    icon: <FaTruckLoading />,
  },
  {
    name: "Usuarios",
    layout: "/admin",
    icon: "",
    path: "users", // Sin barra inicial
    component: <Users />,
    associateBd: 5,
    icon: <FaUsersCog />,
  },
  {
    name: "Bancos",
    layout: "/admin",
    icon: "",
    path: "banks", // Sin barra inicial
    component: <Banks />,
    associateBd: 14,
    icon: <FaPiggyBank />,
  },
  {
    name: "Datas",
    layout: "/admin",
    icon: "",
    path: "datas", // Sin barra inicial
    component: <Datas />,
    associateBd: 15,
    icon: <FaDatabase />,
  },
  {
    name: "Log",
    layout: "/admin",
    icon: "",
    path: "logs", // Sin barra inicial
    component: <Logs />,
    associateBd: 16,
    icon: <GiWoodenClogs />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in", // Sin barra inicial
    icon: "",
    component: <SignIn />,
    notBar: true,
  },
];

export default routes;
