import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Badge from "./Badge";

export function SidebarLinks(props) {
  const location = useLocation();
  const {
    countSolicitadas,
    countDevueltas,
    countAtender,
    countCanceladas,
    countReasignar,
    countProceso,
  } = useSelector((state) => state.auth);

  const { routes, permissions } = props;

  console.log({
    countSolicitadas,
    countDevueltas,
    countAtender,
    countCanceladas,
    countReasignar,
    countProceso,
  })

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const notificaciones = useMemo(() => {
    return {
      18: countSolicitadas,
      4: countDevueltas,
      8: countAtender,
      10: countCanceladas,
      7: countReasignar,
      19: countProceso,
    };
  }, [
    countSolicitadas,
    countDevueltas,
    countAtender,
    countCanceladas,
    countReasignar,
    countProceso,
  ]);

  const createLinks = (routes) => {
    return routes
      .filter((item) => permissions.includes(item.associateBd))
      .map((route, index) => {
        if (route.layout === "/admin" || route.layout === "/rtl") {
          const isActive = activeRoute(route.path);
          const linkClass = isActive
            ? "font-bold text-brand-500 dark:text-white"
            : "text-[16px] text-gray-600";
          const textClass = isActive
            ? "font-bold text-navy-700 dark:text-white"
            : "text-[16px] text-gray-600";
          const indicatorClass = isActive
            ? "absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400"
            : "";

          return (
            <React.Fragment key={route.name}>
              <li className="my-[3px] flex cursor-pointer items-center px-8">
                <Link
                  to={
                    route.urlFull
                      ? route.urlFull
                      : route.layout +
                        (route.path.startsWith("/")
                          ? route.path
                          : "/" + route.path)
                  } // Concatenar layout y path
                  className="relative mb-3 flex w-full items-center hover:cursor-pointer"
                >
                  <span className={linkClass}>
                    {route.icon ? route.icon : null}
                  </span>
                  <p className={`leading-1 ml-4 flex ${textClass}`}>
                    {route.name}
                  </p>
                  {notificaciones.hasOwnProperty(route.associateBd) ? (
                    <Badge value={notificaciones[route.associateBd]} />
                  ) : null}
                  {!route.children && isActive && (
                    <div className={indicatorClass} />
                  )}
                </Link>
              </li>

              {/* Recursividad para mostrar los submenús */}
              {route.children && (
                <ul className="ml-3">{createLinks(route.children)}</ul>
              )}
            </React.Fragment>
          );
        }
        return null;
      });
  };

  return <ul>{createLinks(routes)}</ul>;
}

export default SidebarLinks;
