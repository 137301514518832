import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const bgs = {
  autorizada: "bg-green-200",
  Devuelta: "bg-yellow-100",
  reasignacion: "bg-yellow-100",
  cancelada: "bg-yellow-100",
  solicitada: "bg-yellow-100",
  "por-atender": "bg-yellow-100",
};

const Dashboard = () => {
  const { token } = useSelector((state) => state.auth);
  const { REACT_APP_URL_BASE } = process.env;

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (token) {
      getDashboard();
    }
  }, [token]);

  const getDashboard = () => {
    const endpoint = `${REACT_APP_URL_BASE}/solicitudes/dashboard`;
    setLoading(true);
    const options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    fetch(endpoint, options)
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          setData(json.filter((a) => a.estatus !== "captura"));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="grid grid-cols-1 gap-6 p-6 lg:grid-cols-1">
      <div className="rounded-lg bg-white p-6 shadow-md">
        <div className="mb-6">
          <h2 className="text-lg font-bold">Reporte diario</h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {loading ? (
            <>
              <div
                role="status"
                class="animate-pulse space-y-8 rtl:space-x-reverse md:flex md:items-center md:space-y-0 md:space-x-8"
              >
                <div class="flex h-48 w-full items-center justify-center rounded bg-gray-300 dark:bg-gray-700 sm:w-96"></div>
              </div>
            </>
          ) : data ? (
            data.map((a) => {
              return (
                <div className={`rounded-lg ${bgs[a.estatus]} p-4 shadow-md`}>
                  <p className="text-gray-600">{a.estatus}</p>
                  <h3 className="text-2xl font-bold">{a.conteo}</h3>
                </div>
              );
            })
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
