import InputField from "components/fields/InputField";
import ModalGeneral from "components/modal";
import { useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import Swal from "sweetalert2";
import { Observaciones } from "../obrservaciones";

import { IoEyeSharp } from "react-icons/io5";
import { FaRegComments } from "react-icons/fa";
import { MdWarning } from "react-icons/md";
import { useLocation } from "react-router-dom";
import Magnifier from "react-magnifier";

const inputsList = [
  {
    type: "text",
    label: "NOMBRE",
    prop: "nombre",
  },
  {
    type: "text",
    label: "Segundo nombres",
    prop: "secondName",
  },
  {
    type: "text",
    label: "PRIMER APELLIDO",
    prop: "apellidoP",
  },
  {
    type: "text",
    label: "SEGUNDO APELLIDO",
    prop: "apellidoM",
  },
  {
    type: "text",
    label: "FECHA DE NACIMIENTO",
    prop: "fechaNacimiento",
  },
  {
    type: "text",
    label: "CURP",
    prop: "curp",
  },
  {
    type: "text",
    label: "RFC",
    prop: "rfc",
  },
  {
    type: "text",
    label: "ESTADO CIVIL",
    prop: "estadoCivil",
  },
  {
    type: "text",
    label: "SEXO",
    prop: "genero",
  },
];

const inputListAddress = [
  {
    type: "text",
    label: "CALLE",
    prop: "calle",
  },
  {
    type: "text",
    label: "NO EXTERIOR",
    prop: "nExterior",
  },
  {
    type: "text",
    label: "NO INTERIOR",
    prop: "nInterior",
  },
  {
    type: "text",
    label: "COLONIA/URBANIZACIÓN",
    prop: "colonia",
  },
  {
    type: "text",
    label: "CÓDIGO POSTAL",
    prop: "codigoPostal",
  },
  {
    type: "text",
    label: "DELEGACIÓN/MUNICIPIO",
    prop: "delegacion",
  },
  {
    type: "text",
    label: "CIUDAD/POBLACIÓN",
    prop: "ciudad",
  },
  {
    type: "text",
    label: "ESTADO/ENTIDAD FEDERATIVA",
    prop: "estado",
  },
];

const arrrayDos = [
  {
    type: "text",
    label: "TELÉFONO CELULAR",
    prop: "phone",
  },
  {
    type: "text",
    label: "CORREO ELECTRÓNICO",
    prop: "email",
  },
  {
    type: "text",
    label: "FECHA DE INGRESO LABORAL *",
    prop: "ingreso",
  },
  {
    type: "text",
    label: "NÚMERO DE EMPLEADO *",
    prop: "numero_empleado",
  },
  {
    type: "text",
    label: "CONVENIO DEL CLIENTE *",
    prop: "convenio",
  },
  {
    type: "text",
    label: "DEPENDENCIA",
    prop: "dependencia",
  },
  {
    type: "text",
    label: "UNIVERSO *",
    prop: "universo",
  },
  {
    type: "text",
    label: "TIPO DE NÓMINA",
    prop: "nomina",
  },
  {
    type: "text",
    label: "SECC SINDICAL *",
    prop: "secc_sindical",
  },
  {
    type: "text",
    label: "TIPO DE LEY *",
    prop: "tipo_ley",
  },
  {
    type: "text",
    label: "COMISIÓN DE SINDICAL *",
    prop: "comision_sindical",
  },
  {
    type: "text",
    label: "NIVEL *",
    prop: "nivel",
  },
  {
    type: "text",
    label: "CODIGO DE PUESTO *",
    prop: "codigo_puesto",
  },
  {
    type: "text",
    label: "NÚMERO DE PLAZA *",
    prop: "numero_plaza",
  },
  {
    type: "text",
    label: "ZONA PAGADORA *",
    prop: "zona_pagadora",
  },
  {
    type: "text",
    label: "UNIDAD ADMINISTRATIVA *",
    prop: "unidad_administrativa",
  },

  {
    type: "text",
    label: "FOLIO FISCAL",
    prop: "folioFiscal",
  },
];
const ViewDatos = ({ id, close, rol }) => {
  const { REACT_APP_URL_BASE } = process.env;

  const [comentarios, setComentarios] = useState(false);
  const [modalComentarios, setModalComentarios] = useState(false);
  const [solicitud, setSolicitud] = useState({});
  const [addressChanged, setAddressChanged] = useState(false);

  const location = useLocation();

  const isDevoluciones = useMemo(() => {
    return location.pathname.includes("/admin/devoluciones");
  }, [location]);

  const isAtender = useMemo(() => {
    return location.pathname.includes("/admin/por-atender");
  }, [location]);

  const CloseModalComentarios = () => {
    setModalComentarios(false);
    getSolicitudById();
  };

  const [general, setGeneral] = useState();
  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  useEffect(() => {
    getSolicitudById();
  }, [id]);

  useEffect(() => {
    if (solicitud?.general) setGeneral(solicitud?.general ?? {});
  }, [solicitud]);

  const address = useMemo(() => {
    return solicitud?.address;
  }, [solicitud]);

  const documentos = useMemo(() => {
    return solicitud?.documents ?? [];
  }, [solicitud]);

  const account = useMemo(() => {
    return solicitud?.account ?? {};
  }, [solicitud]);
  const isComment = useMemo(() => {
    if (!documentos) return false;
    const comentarioExiste = documentos.some((doc) => doc.comments);
    return comentarioExiste;
  }, [documentos]);

  const getSolicitudById = () => {
    const endpoint = `${REACT_APP_URL_BASE}/solicitudes/${id}`;
    fetch(
      endpoint,

      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          setSolicitud(json);
        }
      });
  };

  const OpenModalComentarios = (document) => {
    if (document) {
      setComentarios(document);
      setModalComentarios(true);
    }
  };

  const buildDto = () => {
    return {
      CodigoPostal: address.codigoPostal,
      Calle: address.calle,
      NExterior: address.nExterior,
      NInterior: address.nInterior,
      Ciudad: address.ciudad,
      Estado: address.estado,
      Delegacion: address.delegacion,
      Colonia: address.colonia,
    };
  };

  const handleSave = () => {
    if (!validateFields()) {
      Swal.fire({
        title: "Por favor rellena todos los campos de datos.",
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    Swal.fire({
      title: "¿Desea guardar los cambios?",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const options = {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(general),
        };

        const endpoint = `${REACT_APP_URL_BASE}/solicitudes/${general.id}`;
        await fetch(endpoint, options).then((res) => res.json());
      }

      if (addressChanged) {
        const body = buildDto();
        // Envía la dirección completa al servidor
        const endpoint = `${REACT_APP_URL_BASE}/solicitudes/${general.id}/direccion`; // Usar solicitud en lugar de generales
        const options = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body), // Usar solicitud en lugar de updatedSolicitud
        };
        fetch(endpoint, options)
          .then((res) => res.json())
          .then((data) => {
            // Aquí puedes manejar la respuesta del servidor si es necesario
          })
          .catch((error) => {
            // Aquí puedes manejar los errores si es necesario
          });
      }
    });
  };

  const handleDevolverClick = (status) => {

    Swal.fire({
      title: status
        ? "Por favor confirma el cambio de estatus de la solicitud"
        : "Por favor confirma la devolución de solicitud.",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cerrar",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const options = {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(status ? status : "Devuelta"),
        };

        const endpoint = `${REACT_APP_URL_BASE}/solicitudes/${general.id}/change-status`;
        fetch(endpoint, options).finally(() => {
          close();
        });
      }
    });
  };

  const renderBtnSave = () => {
    if (general?.documentId) return null;
    if ([1, 4, 6, 7].includes(rol)) {
      return (
        <button
          style={{ background: "#585858" }}
          className="guardar linear hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-brand-500 dark:text-white"
          onClick={handleSave}
        >
          Guardar cambios
        </button>
      );
    }
  };

  const renderBtnDevolver = () => {
    if (isDevoluciones) return null;
    if (!isComment) return null;
    if ([1, 4, 6, 7].includes(rol)) {
      return (
        <button
          style={{ background: "#585858" }}
          onClick={() => handleDevolverClick()}
          className="linear  hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover mt-2 mt-[80px] w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-brand-500 dark:text-white"
        >
          Devolver
        </button>
      );
    }
  };

  const validateFields = () => {
    const allGeneralFieldsFilled = inputsList.every(
      (item) => general && general[item.prop]
    );
    const allAddressFieldsFilled = inputListAddress.every(
      (item) => address && address[item.prop]
    );

    return allGeneralFieldsFilled && allAddressFieldsFilled;
  };

  return (
    <ModalGeneral title="Datos de la solicitud" close={close}>
      <div className="flex h-full w-full flex-row items-center">
        <div className="flex h-[650px] w-[350px] flex-col overflow-y-scroll px-2">
          {inputsList.map((item, index) => (
            <InputField
              key={`general-${index}`}
              extra="mb-3"
              label={item.label}
              placeholder=""
              id={item.prop}
              type={item.type}
              value={general && general[item.prop] ? general[item.prop] : ""}
              onChange={(e) => {
                const value = e.target.value;
                setGeneral((old) => ({
                  ...old,
                  [item.prop]: value,
                }));
              }}
            />
          ))}
          {inputListAddress.map((item, index) => (
            <InputField
              key={`address-${index}`}
              extra="mb-3"
              label={item.label}
              placeholder=""
              id={item.prop}
              type={item.type}
              value={address && address[item.prop] ? address[item.prop] : ""}
              onChange={(e) => {
                const value = e.target.value;
                const updatedAddress = { ...address, [item.prop]: value };
                setSolicitud((prevSolicitud) => ({
                  ...prevSolicitud,
                  address: updatedAddress,
                }));
                setAddressChanged(true);
              }}
            />
          ))}

          {arrrayDos.map((item, index) => (
            <InputField
              key={`general-${index}`}
              extra="mb-3"
              label={item.label}
              placeholder=""
              id={item.prop}
              type={item.type}
              value={general && general[item.prop] ? general[item.prop] : ""}
              onChange={(e) => {
                const value = e.target.value;
                setGeneral((old) => ({
                  ...old,
                  [item.prop]: value,
                }));
              }}
            />
          ))}
          <InputField
            key={`general-99`}
            extra="mb-3"
            label={"Cuenta clabe"}
            placeholder=""
            id={"cuenta-clave"}
            type={"text"}
            value={account ? account.account : ""}
            disabled={true}
          />
        </div>
        <div className="h-[650px] flex-1  overflow-y-scroll px-2">
          {[
            {
              text: "INE FRENTE",
              type: "ine_front",
            },
            {
              text: "INE REVERSO",
              type: "ine_reverso",
            },
            {
              text: "SELFIE",
              type: "selfie_veridas",
            },
            {
              text: "VIDEO DE ACEPTACION",
              type: "vide_aceptacion",
            },
            {
              text: "Penúltimo recibo de nomina ( front )",
              type: "penultimo_nomina_front",
            },
            {
              text: "Penúltimo recibo de nomina ( reverso )",
              type: "penultimo_nomina_reveso",
            },
            {
              text: "Ultimo recibo de nómina",
              type: "ultimo_nomina",
            },
            {
              text: "Último recibo de nómina ( reverso )",
              type: "ultimo_nomina_reveso",
            },
            {
              text: "Carátula estado de cuenta",
              type: "caratula_estado_de_cuenta",
            },
            {
              text: "Documento adicional 1",
              type: "documento_adicional_uno",
            },
            {
              text: "Documento adicional 2",
              type: "documento_adicional_dos",
            },
          ].map((item) => {
            const document = documentos.find((doc) => doc.type === item.type);

            if (!document) return null;

            if (item.type === "vide_aceptacion") {
              return (
                <div>
                  <h5>{item.text}</h5>
                  <ReactPlayer
                    url={document?.file}
                    controls={true}
                    muted={false}
                    volume={1}
                  />
                </div>
              );
            }

            return (
              <div>
                <h5 className="font-bold">{item.text}</h5>
                <Magnifier
                  src={document?.file}
                  zoomFactor={1.5}
                  mgHeight={350}
                  mgWidth={350}
                  mgShape="square"
                />
              </div>
            );
          })}
        </div>
        <div className="h-[750px] w-[350px] overflow-y-scroll px-2 px-2 pr-4">
          {[
            {
              text: "INE de Frente",
              type: "ine_front",
            },
            {
              text: "INE de Reverso",
              type: "ine_reverso",
            },
            { text: "Video aceptación", type: "vide_aceptacion" },
            {
              text: "Penúltimo recibo de nomina",
              type: "penultimo_nomina_front",
            },

            {
              text: "Penúltimo recibo de nomina ( reverso )",
              type: "penultimo_nomina_reveso",
            },
            {
              text: "Ultimo recibo de nómina",
              type: "ultimo_nomina",
            },
            {
              text: "Último recibo de nómina ( reverso )",
              type: "ultimo_nomina_reveso",
            },
            {
              text: "Carátula estado de cuenta",
              type: "caratula_estado_de_cuenta",
            },
            {
              text: "Documento adicional 1",
              type: "documento_adicional_uno",
            },
            {
              text: "Documento adicional 2",
              type: "documento_adicional_dos",
            },
          ].map((item, index) => {
            const document = documentos.find((doc) => doc.type === item.type);
            return (
              <div
                className="linear hover:bg-brand-500-hover relative mt-2 flex w-full flex-row justify-between rounded-xl bg-brand-500 p-2 py-[12px] text-base font-medium text-white transition duration-200"
                key={index}
              >
                <span style={{ fontSize: 12 }}>{item.text}</span>
                <div className="flex flex-row gap-4">
                  <button
                    onClick={() => {
                      if (item.onPress) return item.onPress();
                      if (document) {
                        window.open(
                          document.file,
                          "_blank",
                          "noopener noreferrer"
                        );
                      }
                    }}
                    title="Ver documento"
                  >
                    <IoEyeSharp size={22} />
                  </button>

                  {rol === 8 ? null : (
                    <button
                      onClick={() =>
                        OpenModalComentarios({ ...document, title: item.text })
                      }
                      title="Agregar comentario"
                    >
                      {document?.comments ? (
                        <MdWarning size={22} />
                      ) : (
                        <FaRegComments size={22} />
                      )}
                    </button>
                  )}
                </div>
              </div>
            );
          })}

          {renderBtnDevolver()}

          {isAtender && !isComment && (
            <button
              style={{ background: "#585858" }}
              onClick={() => handleDevolverClick("proceso")}
              className="linear  hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover mt-2 mt-[80px] w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-brand-500 dark:text-white"
            >
              Enviar a proceso
            </button>
          )}

          {renderBtnSave()}
        </div>
      </div>
      {modalComentarios && (
        <Observaciones
          close={CloseModalComentarios}
          token={token}
          title={`Comentarios ${comentarios?.title}`}
          boton="Guardar Comentarios"
          document={comentarios}
          defaultText={comentarios?.comments}
          rol={rol}
          isDevoluciones={isAtender}
        />
      )}
    </ModalGeneral>
  );
};

export default ViewDatos;
