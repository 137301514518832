// authSlice.ts

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countSolicitadas: 0,
  countDevueltas: 0,
  countAtender: 0,
  countCanceladas: 0,
  countReasignar: 0,
  countProceso: 0,
  token: null,
  rol: 0,
  routes: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setNotificacion(state, action) {
      const { type, value } = action.payload;
      console.log({
        type, value
      })
      if (type === "solicitada") {
        const $number = state.countSolicitadas + value;
        state.countSolicitadas = $number;
      } else if (type === "Devuelta") {
        const $number = state.countDevueltas + value;
        state.countDevueltas = $number;
      } else if (type === "por-atender") {
        const $number = state.countDevueltas + value;
        state.countAtender = $number;
      } else if (type === "cancelada") {
        const $number = state.countDevueltas + value;
        state.countCanceladas = $number;
      } else if (type === "reasignacion") {
        const $number = state.countReasignar + value;
        state.countReasignar = $number;
      } else if (type === "proceso") {
        const $number = state.countProceso + value;
        state.countProceso = $number;
      }
    },
    setDataAuht(state, action) {
      const { token, rol, routes } = action.payload;
      state.token = token;
      state.rol = rol;
      state.routes = routes;
    },
  },
});

export const { setNotificacion, setDataAuht } = authSlice.actions;

export default authSlice.reducer;
